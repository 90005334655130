import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout sectionTitle={"Oeps"}>
    <SEO title="404: Not found" />
    <Container>
      <h1>Oeps, deze pagina is niet gevonden</h1>
      <Link style={{ position: "relative", zIndex: 3 }} to={"/"}>
        Klik hier om terug te keren naar de homepagina
      </Link>
    </Container>
  </Layout>
)

export default NotFoundPage
